import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";

export const StyledBoxMyAccountHeaderWrapper = styled.div<{
  alignment: AlignmentHorizontalOption;
}>`
  display: grid;
  place-content: ${({ alignment }) => alignmentOptionsToValue(alignment)};

  .my-account-header-container__button-open-container {
    position: relative;
    display: flex;
    gap: 10px;
    z-index: var(--zindex-button-open-myaccount-header-container);
    align-items: center;
    cursor: pointer;
  }
`;
