import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const StyledDropdownWrapper = styled.div`
  width: 100%;
  z-index: var(--zindex-dropdown-wrapper);
`;

export const StyledDropdownContent = styled.div<{
  wrapperWidth: number | null;
  left: number | null;
  top: number | null;
  openHorizontalDirection: AlignmentHorizontalOption;
  fixedWidth?: boolean;
  menuWrapperWidth?: number;
  menuWrapperPercentageWidth?: number;
}>`
  position: absolute;
  z-index: var(--zindex-dropdown-content);

  ${({ wrapperWidth, openHorizontalDirection, fixedWidth }) =>
    openHorizontalDirection === AlignmentHorizontalOption.LEFT &&
    wrapperWidth &&
    !fixedWidth &&
    `width:${wrapperWidth}px`};

  ${({
    left,
    wrapperWidth,
    openHorizontalDirection,
    menuWrapperWidth,
    menuWrapperPercentageWidth,
  }) => {
    if (left === null || !wrapperWidth) return;

    if (menuWrapperWidth && menuWrapperPercentageWidth) {
      const offsetLeft =
        left -
        (menuWrapperWidth -
          (menuWrapperPercentageWidth / 100) * menuWrapperWidth);

      return `left: ${offsetLeft}px;`;
    }

    if (openHorizontalDirection === AlignmentHorizontalOption.CENTER) {
      return `
        left: ${left + wrapperWidth / 2}px;
        transform: translateX(-50%);
      `;
    }

    return openHorizontalDirection === AlignmentHorizontalOption.LEFT
      ? `right :${window.innerWidth - left - wrapperWidth}px`
      : `left :${left}px`;
  }};
  ${({ top }) => top && `top:${top}px`};
  ${({ menuWrapperWidth, left }) =>
    menuWrapperWidth && `width: calc(${menuWrapperWidth}px - ${left}px)`};
`;

export const Wrapper = styled.div`
  top: 0px;
  right: 0px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--zindex-multimenu-content);
`;

export const BackDropElement = styled.div<{
  isMobile: boolean;
}>`
  ${({ isMobile }) => `position: ${isMobile ? "absolute" : "fixed"}`};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;
