import { RefObject, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";

const MARGIN_BETWEEN_WRAPPER_AND_CONTENT = 0;
const OFFSET_CHECK_WINDOW_HEIGHT = 10;
const SPACE_WRAPPER_TO_WINDOW = 15;
const DEBOUNCE_TIME = 110;

const useDropdownDimensions = (
  isOpen: boolean,
  dropdownWrapper: RefObject<HTMLDivElement> | RefObject<HTMLUListElement>,
  dropdownContent: RefObject<HTMLDivElement>,
  isResponsive?: boolean
) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [dropdownContentDimensions, setDropdownContentDimensions] = useState<{
    wrapperWidth?: number;
    left?: number;
    top?: number;
    height?: number;
    right?: number;
  }>();

  const setContainerInitialDimensios = useCallback(() => {
    if (!dropdownWrapper.current) return;
    const { width, left, bottom, height, right } =
      dropdownWrapper.current.getBoundingClientRect();

    setDropdownContentDimensions({
      top: bottom + MARGIN_BETWEEN_WRAPPER_AND_CONTENT,
      left: left,
      wrapperWidth: width,
      height,
      right,
    });
  }, [dropdownWrapper.current, setDropdownContentDimensions]);

  useEffect(() => {
    if (!dropdownContent.current) return;
    const { height, bottom } = dropdownContent.current.getBoundingClientRect();
    if (
      isResponsive &&
      bottom > window.innerHeight - OFFSET_CHECK_WINDOW_HEIGHT
    ) {
      setDropdownContentDimensions({
        ...dropdownContentDimensions,
        top: window.innerHeight - height - SPACE_WRAPPER_TO_WINDOW,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen, setDropdownContentDimensions]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    const debounceListener = debounce(() => {
      setContainerInitialDimensios();
    }, DEBOUNCE_TIME);

    window.addEventListener("resize", debounceListener);
    window.addEventListener("keydown", debounceListener);
    return () => {
      window.removeEventListener("resize", debounceListener);
      window.removeEventListener("keydown", debounceListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  useEffect(() => {
    setDropdownOpen(isOpen);
    setContainerInitialDimensios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, mounted]);

  return { dropdownContentDimensions, dropdownOpen };
};

export default useDropdownDimensions;
