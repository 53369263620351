import styled from "styled-components";
import {
  IBoxMyAccountHeaderMessages,
  IBoxMyAccountHeaderSettings,
} from "../BoxMyAccountHeader.types";

export const StyledWrapperMyAccountHeader = styled.div<
  IBoxMyAccountHeaderSettings & IBoxMyAccountHeaderMessages
>(({ gap, title_button_gap, register_title, login_title }) => {
  return `
      box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
      width: fit-content;
      display: flex;
      flex-direction: column;

      .background-login, 
      .background-register {
        padding: ${gap};
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: ${register_title ? `${gap}` : "0"};
        gap: ${gap};

        .button-login {
          flex-grow: 1;
          ${login_title ? `margin-top: ${title_button_gap}` : ""}
        }
        
        .button-register {
          flex-grow: 1;
          ${register_title ? `margin-top: ${title_button_gap}` : ""}

        }
      }

      .my-account-header-container__dropdown__register-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `;
});
