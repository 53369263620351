import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

export const StyledBackground = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return `
      background-color: ${bg};
    `;
});
