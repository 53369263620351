import React, { useState } from "react";
import withHydrationOnDemand from "react-hydration-on-demand";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import Dropdown from "../../shared/components/Dropdown/Dropdown";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { StyledBoxMyAccountHeaderWrapper } from "./BoxMyAccountHeader.styled";
import { IBoxMyAccountHeaderProps } from "./BoxMyAccountHeader.types";
import BoxMyAccountHeaderDropdown from "./elements/BoxMyAccountHeaderDropdown";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";

const BoxMyAccountHeader: React.FC<IBoxMyAccountHeaderProps> = ({
  settings,
  messages,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseOver = () => {
    settings.action_on_icon === "HOVER" && setIsOpen(true);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleBackdropMouseOver = () => {
    if (settings.action_on_icon === "HOVER") {
      setIsOpen(false);
    }
  };

  const handleMouseClick = () => {
    if (settings.action_on_icon === "CLICK") {
      setIsOpen((prev) => !prev);
    }
  };
  return (
    <StyledBoxMyAccountHeaderWrapper
      className="my-account-header-container my-account-header-custom-style"
      alignment={settings.button_position}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      data-testid="dropdown-container"
    >
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        actionType={settings.action_on_icon}
        openHorizontalDirection={settings.dropped_container_position}
        onBackDropClick={() => {
          setIsOpen(false);
        }}
        onBackDropMouseOver={handleBackdropMouseOver}
        fixedWidth
        buttonContent={
          <div
            className="my-account-header-container__button-open-container"
            data-testid="button-open-my-account-header"
          >
            <StyledButton
              $settings={settings.button_dropdown}
              renderAs="button"
              className="my-account-header-container__dropdown-button"
              onClick={handleMouseClick}
              show={isNotEmptyMessage(messages.button_dropdown_text)}
            >
              {messages.button_dropdown_text}
            </StyledButton>
          </div>
        }
        selectContainerContent={<BoxMyAccountHeaderDropdown />}
      />
    </StyledBoxMyAccountHeaderWrapper>
  );
};

export default Memo(
  withHydrationOnDemand({ on: ["visible"] })(BoxMyAccountHeader)
);
